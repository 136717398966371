import React from "react"
import VideoCard from "../components/VideoCard"
import VideoGrid from "../components/VideoGrid"

const VideoList = ({ videos, categorySlug }) => {
  return (
    <VideoGrid>
      {videos.map(video => {
        const { title, slug, poster, description } = video
        return (
          <VideoCard
            categorySlug={categorySlug}
            slug={slug}
            poster={poster}
            title={title}
            description={description && description.description}
            key={slug}
          />
        )
      })}
    </VideoGrid>
  )
}

export default VideoList

import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

const VideoCard = ({ categorySlug, slug, poster, title, description }) => {
  return (
    <Link to={`/${categorySlug}/${slug}/`} className="group">
      {poster ? (
        <div className="relative overflow-hidden rounded">
          <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-yellow-500 opacity-0 group-hover:opacity-25"></div>
          <Image fluid={poster.fluid} />
        </div>
      ) : (
        <div className="flex items-center justify-center h-full text-gray-600 bg-gray-900 rounded">
          Kuva tulossa
        </div>
      )}
      <h3 className="mt-2 font-semibold leading-tight text-white">{title}</h3>
      {description && (
        <p className="mt-1 text-sm leading-tight text-gray-400">
          {description}
        </p>
      )}
    </Link>
  )
}

export default VideoCard

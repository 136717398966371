import React from "react"
import Layout from "../layouts/default"
import { graphql } from "gatsby"
import CategoryTitle from "../components/CategoryTitle"
import VideoList from "../components/VideoList"
import VideoCard from "../components/VideoCard"
import VideoGrid from "../components/VideoGrid"

const Home = ({ data }) => {
  const pageData = {
    title: data.homeData.title,
    description: data.homeData.description.description,
    isFront: true,
  }
  const { edges: categories } = data.allCategories
  const { edges: popular } = data.popularVideos
  return (
    <Layout pageData={pageData}>
      <section className="relative z-30 -mt-12 text-white md:-mt-24">
        <div className="row">
          <div className="space-y-16 col md:space-y-24">
            <div>
              <CategoryTitle title="Suosituimmat" />
              <VideoGrid>
                {popular.map(({ node }) => {
                  const { slug, title, poster, category, description } = node
                  return (
                    category && (
                      <VideoCard
                        categorySlug={category[0].slug}
                        slug={slug}
                        title={title}
                        poster={poster}
                        description={description && description.description}
                        key={slug}
                      />
                    )
                  )
                })}
              </VideoGrid>
            </div>
            {categories.map(({ node }) => {
              const { shortTitle, slug: categorySlug, categoryVideos } = node
              return (
                <div className="mb-8 md:mb-12" key={categorySlug}>
                  <CategoryTitle slug={categorySlug} title={shortTitle} />
                  <VideoList
                    videos={categoryVideos}
                    categorySlug={categorySlug}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    homeData: contentfulPage {
      title
      description {
        description
      }
    }
    allCategories: allContentfulCategory(sort: { fields: order }) {
      edges {
        node {
          shortTitle
          slug
          categoryVideos {
            slug
            title
            # description {
            #   description
            # }
            poster {
              fluid(maxWidth: 400, maxHeight: 225) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
    popularVideos: allContentfulVideo(
      sort: { fields: order }
      filter: { favorite: { eq: true } }
    ) {
      edges {
        node {
          slug
          title
          # description {
          #   description
          # }
          poster {
            fluid(maxWidth: 400, maxHeight: 225) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          category {
            slug
          }
        }
      }
    }
  }
`

import React from "react"
import { Link } from "gatsby"

const CategoryTitle = ({ slug, title }) => {
  return (
    <>
      <div className="w-8 h-1 mb-2 bg-yellow-500"></div>
      {slug ? (
        <Link to={`/${slug}/`} className="block hover:text-yellow-500">
          <h2 className="mb-4 text-xl font-bold md:text-3xl">{title} &rarr;</h2>
        </Link>
      ) : (
        <h2 className="mb-4 text-xl font-bold md:text-3xl">{title}</h2>
      )}
    </>
  )
}

export default CategoryTitle
